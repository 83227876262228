<template>
  <b-container fluid>
    <app-collapse accordion :type="collapseType">
      <app-collapse-item title="Login Methodu">
        <b-row>
          <b-col cols="7">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="10%">Request</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>serviceName</strong></td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      placeholder="Small Input"
                      value="account"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>methodName</strong></td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      placeholder="Small Input"
                      value="logIn"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>user</strong></td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      v-model="login.user"
                      @keyup.enter="post('login')"
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>pwd</strong></td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      v-model="login.pwd"
                      @keyup.enter="post('login')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="5">
            <b-button
              @click="sifirla"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="d-block"
            >
              Sıfırla
            </b-button>
            <b-button
              @click="post('login')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-block mt-50"
            >
              Gönder
            </b-button>
          </b-col>
        </b-row>
        <json-viewer
          class="mt-1"
          theme="jv-light"
          :value="postReply"
          :expand-depth="5"
          copyable
          boxed
          sort
        />
      </app-collapse-item>
      <app-collapse-item title="Kurum Listesi">
        <b-row>
          <b-col cols="7">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="10%">Request</th>
                  <th>Değer</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>serviceName</strong></td>
                  <td>
                    <code>oys</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      placeholder="Small Input"
                      value="oys"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>methodName</strong></td>
                  <td>
                    <code>firmaListesi</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      placeholder="Small Input"
                      value="firmaListesi"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>crm_token</strong></td>
                  <td>
                    <code>__userToken__</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      v-model="kurum.token"
                      @keyup.enter="post('kurum')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="5">
            <b-button
              @click="sifirla"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="d-block"
            >
              Sıfırla
            </b-button>
            <b-button
              @click="post('kurum')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-block mt-50"
            >
              Gönder
            </b-button>
          </b-col>
        </b-row>
        <json-viewer
          class="mt-1"
          theme="jv-light"
          :value="postReply"
          :expand-depth="5"
          copyable
          boxed
          sort
        />
      </app-collapse-item>
      <app-collapse-item title="Aylık Tarife Ve Konaklama Raporu">
        <b-row>
          <b-col cols="7">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="10%">Request</th>
                  <th>Değer</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>serviceName</strong></td>
                  <td>
                    <code>firma</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      placeholder="Small Input"
                      value="firma"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>methodName</strong></td>
                  <td>
                    <code>aylikRapor</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      placeholder="Small Input"
                      value="aylikRapor"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>crm_token</strong></td>
                  <td>
                    <code>__userToken__</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      v-model="aylikRapor.token"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>firmaKodu</strong></td>
                  <td>
                    <code>firmaKodu</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      v-model="aylikRapor.firmaKodu"
                      @keyup.enter="post('aylik')"
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>yil</strong></td>
                  <td>
                    <code>yil</code>
                  </td>
                  <td>
                    <b-form-input
                      placeholder="demo:   2023"
                      id="smallInput"
                      size="sm"
                      v-model="aylikRapor.yil"
                      @keyup.enter="post('aylik')"
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>ay</strong></td>
                  <td>
                    <code>ay</code>
                  </td>
                  <td>
                    <b-form-input
                      id="smallInput"
                      size="sm"
                      v-model="aylikRapor.ay"
                      @keyup.enter="post('aylik')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="5">
            <b-button
              @click="sifirla"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="d-block"
            >
              Sıfırla
            </b-button>
            <b-button
              @click="post('aylik')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-block mt-50"
            >
              Gönder
            </b-button>
          </b-col>
        </b-row>
        <json-viewer
          class="mt-1"
          theme="jv-light"
          :value="postReply"
          :expand-depth="5"
          copyable
          boxed
          sort
        />
      </app-collapse-item>
    </app-collapse>
  </b-container>
</template>

<script>
import {
  VBTooltip,
  BButton,
  BFormGroup,
  BInputGroup,
  BCard,
  BRow,
  BCol,
  BContainer,
  BNavbarNav,
  BNavItem,
  BTooltip,
  BNavItemDropdown,
  BFormInput,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import "vue-json-viewer/style.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    CodeHighlight,
    BButton,
    BFormGroup,
    BInputGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
  },
  data() {
    return {
      collapseType: "border",
      count: 0,
      postReply: [],
      serviceName: "",
      methodName: "",
      login: {
        firmaKodu: "",
        user: "",
        pwd: "",
      },
      kurum: {
        token: "",
      },
      aylikRapor: {
        token: "",
        firmaKodu: "",
        yil: "",
        ay: "",
      },
    };
  },
  computed: {},
  methods: {
    sifirla() {
      this.postReply = [];
      this.firmaKodu = "";
      this.user = "";
      this.pwd = "";
    },
    post(method) {
      switch (method) {
        case "login":
          this.serviceName = "account";
          this.methodName = "logIn";
          break;
        case "kurum":
          this.serviceName = "oys";
          this.methodName = "firmaListesi";
          break;
        case "aylik":
          this.serviceName = "rapor";
          this.methodName = "aylikRapor";
          break;
        default:
      }
      store
        .dispatch("dokumantasyonPOST", {
          serviceName: this.serviceName,
          methodName: this.methodName,
          token: this.kurum.token,
          // aylikRapor.firmaKodu
          firmaKodu: method !== "aylik" ? 1 : this.aylikRapor.firmaKodu,
          [method === "login" ? "user" : false]: this.login.user,
          [method === "login" ? "pwd" : false]: this.login.pwd,
          [method === "aylik" ? "yil" : false]: this.aylikRapor.yil,
          [method === "aylik" ? "ay" : false]: this.aylikRapor.ay,
        })
        .then((res, position = "bottom-right") => {
          method !== "aylik" ? (this.aylikRapor.token = res.userToken) : false;
          this.count++;
          this.postReply = Object.entries(res);
          const mesaj = store.getters.notificationSettings(res);
          if (mesaj) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Başarılı",
                  text: mesaj,
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              },
              { position }
            );
          }
        })
        .catch((err) => {
          this.count++;
          this.postReply = Object.entries(err);
          const mesaj = this.$store.getters.notificationSettings(err);
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Hata!",
                text: mesaj,
                icon: "ThumbsDownIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    kurumSave() {
      store
        .dispatch("kurumSave", {
          tamUnvan: this.tamUnvan,
          unvan: this.unvan,
          il: this.il,
          ilce: this.ilce,
          telefon: this.telefon,
          ePosta: this.ePosta,
          kurumYetkilisi: this.kurumYetkilisi,
          yetkiliTelefon: this.yetkiliTelefon,
          adres: this.adres,
          siteURL: this.siteURL,
          apiKey: this.apiKey,
          CDN: this.CDN,
          saveFirmaKodu: this.saveFirmaKodu,
          user: this.user,
          pwd: this.pwd,
        })
        .then((res, position = "bottom-right") => {
          if (res.result.status === 200) {
            this.$router.push("/");
            const mesaj = this.$store.getters.notificationSettings(res);
            if (mesaj) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Başarılı",
                    text: mesaj,
                    icon: "ThumbsUpIcon",
                    variant: "success",
                  },
                },
                { position }
              );
            }
          } else {
            const mesaj = this.$store.getters.notificationSettings(res);
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Hata!",
                  text: mesaj,
                  icon: "ThumbsDownIcon",
                  variant: "danger",
                },
              });
            }
          }
        })
        .catch((err) => {
          const mesaj = this.$store.getters.notificationSettings(err);
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Hata!",
                text: mesaj,
                icon: "ThumbsDownIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.login.user = localStorage.getItem("userName");
    this.kurum.token = localStorage.getItem("userToken");
    this.aylikRapor.token = localStorage.getItem("userToken");
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    this.aylikRapor.yil = yyyy;
    this.aylikRapor.ay = mm;
  },
};
</script>
<style lang="scss">
.my-awesome-json-theme {
  background: #fff;
  white-space: nowrap;
  color: #525252;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }

  .jv-key {
    color: #111111;
  }

  .jv-item {
    &.jv-array {
      color: #111111;
    }

    &.jv-boolean {
      color: #fc1e70;
    }

    &.jv-function {
      color: #067bca;
    }

    &.jv-number {
      color: #fc1e70;
    }

    &.jv-number-float {
      color: #fc1e70;
    }

    &.jv-number-integer {
      color: #fc1e70;
    }

    &.jv-object {
      color: #111111;
    }

    &.jv-undefined {
      color: #e08331;
    }

    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }

  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }

      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.custom-select {
  width: 150% !important;
  background-color: #65c18c !important;
  color: white !important;
  border-color: transparent;
}

.custom-select option {
  width: 150% !important;
  background-color: white !important;
  color: black !important;
}

.default-select {
  color: black !important;
}

.token .string {
  white-space: normal !important; /* CSS3 */
  white-space: -moz-pre-wrap !important; /* Mozilla, post millennium */
  white-space: -pre-wrap !important; /* Opera 4-6 */
  white-space: -o-pre-wrap !important; /* Opera 7 */
  word-wrap: break-word !important; /* Internet Explorer 5.5+ */
}
</style>
